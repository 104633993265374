export const tokens = {
  size: {
    x0: '0px',
    x1: '4px',
    x2: '8px',
    x3: '12px',
    x4: '16px',
    x6: '24px',
    x8: '32px',
    x10: '40px',
    x16: '64px',
  },
};

export const horizontal_size_tokens = ['x0', 'x1', 'x2', 'x3', 'x4', 'x6'];

export const vertical_size_tokens = ['x0', 'x1', 'x2', 'x4', 'x6', 'x8', 'x10', 'x16'];

export const tailwindSpacingTokens = {
  spacing: {
    px: '1px',
    0: '0',
    1: '4px',
    2: '8px',
    3: '12px',
    4: '16px',
    5: '20px',
    6: '24px',
    7: '28px',
    8: '32px',
    9: '36px',
    10: '40px',
    11: '44px',
    12: '48px',
    13: '52px',
    14: '56px',
    15: '60px',
    16: '64px',
    17: '68px',
    18: '72px',
    19: '76px',
    20: '80px',
  },
};

export const tailwindLargeSpacingTokens = {
  spacing: {
    24: '96px',
    32: '128px',
    40: '160px',
    48: '192px',
    64: '256px',
    96: '384px',
    128: '512px',
    192: '768px',
    224: '896px',
    256: '1024px',
    320: '1280px',
  },
};

export const tailwindFractionalSpacingTokens = {
  spacing: {
    '1/2': '50%',
    '1/3': '33.333333%',
    '2/3': '66.666667%',
    '1/4': '25%',
    '2/4': '50%',
    '3/4': '75%',
    '1/5': '20%',
    '2/5': '40%',
    '3/5': '60%',
    '4/5': '80%',
    '1/6': '16.666667%',
    '2/6': '33.333333%',
    '3/6': '50%',
    '4/6': '66.666667%',
    '5/6': '83.333333%',
    '1/12': '8.333333%',
    '2/12': '16.666667%',
    '3/12': '25%',
    '4/12': '33.333333%',
    '5/12': '41.666667%',
    '6/12': '50%',
    '7/12': '58.333333%',
    '8/12': '66.666667%',
    '9/12': '75%',
    '10/12': '83.333333%',
    '11/12': '91.666667%',
  },
};

export const tailwindMarginTokens = {
  spacing: {
    auto: 'auto',
    '-px': '-1px',
    '-1': '-4px',
    '-2': '-8px',
    '-3': '-12px',
    '-4': '-16px',
    '-5': '-20px',
    '-6': '-24px',
    '-7': '-28px',
    '-8': '-32px',
    '-10': '-40px',
    '-16': '-64px',
    ...tailwindSpacingTokens.spacing,
  },
};

export const tailwindWidthTokens = {
  spacing: {
    auto: 'auto',
    full: '100%',
    screen: '100vw',
    ...tailwindSpacingTokens.spacing,
    ...tailwindLargeSpacingTokens.spacing,
    ...tailwindFractionalSpacingTokens.spacing,
  },
};

export const tailwindMinWidthTokens = {
  spacing: {
    full: '100%',
    0: '0',
    ...tailwindSpacingTokens.spacing,
    ...tailwindLargeSpacingTokens.spacing,
    ...tailwindFractionalSpacingTokens.spacing,
  },
};

export const tailwindMaxWidthTokens = {
  spacing: {
    none: 'none',
    full: '100%',
    ...tailwindSpacingTokens.spacing,
    ...tailwindLargeSpacingTokens.spacing,
    ...tailwindFractionalSpacingTokens.spacing,
  },
};

export const tailwindHeightTokens = {
  spacing: {
    auto: 'auto',
    full: '100%',
    screen: '100vh',
    ...tailwindSpacingTokens.spacing,
    ...tailwindLargeSpacingTokens.spacing,
  },
};

export const tailwindMinHeightTokens = {
  spacing: {
    0: '0',
    full: '100%',
    screen: '100vh',
    ...tailwindSpacingTokens.spacing,
    ...tailwindLargeSpacingTokens.spacing,
  },
};

export const tailwindMaxHeightTokens = {
  spacing: {
    none: 'none',
    full: '100%',
    screen: '100vh',
    ...tailwindSpacingTokens.spacing,
    ...tailwindLargeSpacingTokens.spacing,
  },
};

export default {
  horizontal_size_tokens,
  tailwindFractionalSpacingTokens,
  tailwindHeightTokens,
  tailwindLargeSpacingTokens,
  tailwindMarginTokens,
  tailwindMaxHeightTokens,
  tailwindMaxWidthTokens,
  tailwindMinHeightTokens,
  tailwindMinWidthTokens,
  tailwindSpacingTokens,
  tailwindWidthTokens,
  tokens,
  vertical_size_tokens,
};
